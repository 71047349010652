

































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import { SessionDataArray } from "@/types/interfaces";

const sessionStore = getModule(sessionVuexModule);

export default Vue.extend ({
    data() {
        return {
            isLoading : true,
            isOndemandData : false,
        
            stickyHeaders : true,
            dateSearchable : false,
            defaultOpenedDetails : [1],
            showDetailIcon : false,
            data : []
        }
    },
    computed: {

        sessions(): SessionDataArray {
            return this.sessionsMethod();
        }
    },
    methods: {
        sessionsMethod() {
            /** added this condition, not important but it will get rid of errors
             * and handle sessions smoothly before they are actually created
             **/
            if (
                sessionStore.sessionData.upcomingSessions ||
                sessionStore.sessionData.onDemand
            ) {
                /**
                 * If there are no upcoming items we'll use ondemand data
                 */
                let returnValue = sessionStore.sessionData.upcomingSessions;
    
                if (0 === sessionStore.sessionData.upcomingSessions.length) {
                    this.isOndemandData = true;
                    returnValue = sessionStore.sessionData.onDemand;
                }
    
                return returnValue;
            }
            /**
             * needs to return as an array as this getters is being used by other function
             **/
            return [];
        },
        // Methods
        routeDetails(sessionId: string) {
            this.$router.push({
                name: "EditSession",
                params: {
                    id: sessionId
                }
            });
        }
    },
    /**
     * Lifecycle
     */
    created() {
        const promises = [
            sessionStore.getSessionData({
                isDemoData: false
            })
        ];

        Promise.allSettled(promises).then(() => {
            this.isLoading = false;
        });
    }
})
